import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Pages
const Home = React.lazy(() => import("./components/pages/Home"));
const Error404 = React.lazy(() => import("./components/pages/Error404"));

function App() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/error" component={Error404} />
          <Route exact component={Error404} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
