import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

// Css
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/react-owl-carousel2/lib/styles.css";
import "../node_modules/react-owl-carousel2/src/owl.carousel.css";
import "../node_modules/react-owl-carousel2/src/owl.theme.default.css";
import "../node_modules/magnific-popup/dist/magnific-popup.css";
import "../node_modules/react-circular-progressbar/dist/styles.css";
import "../node_modules/animate.css/animate.css";
import "./assets/css/all.min.css";
import "./assets/css/default.css";
import "./assets/css/style.css";
import "./assets/css/color.css";
import "./assets/css/responsive.css";

ReactDOM.render(
  <BrowserRouter basename={"/themes/themeforest/react/pro-cleankers"}>
    <App />
  </BrowserRouter>,
  document.getElementById("app")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
